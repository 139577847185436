<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col :span="4">
					<a-range-picker
						:placeholder="['生效日期', '生效日期']"
						@change="onActiveDateChange"
						style="width: 100%"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						@change="handleChange"
						allowClear
						multiple
						:placeholder="l('Company')"
						style="width: 100%"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						tree-default-expand-all
						v-model="comPanyId"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						v-model="departmentId"
						allowClear
						style="width: 100%"
						multiple
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						@select="SelectDepartmentTree"
						@change="search"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="4">
					<a-select
						show-search
						allowClear
						showArrow
						mode="tags"
						placeholder="请选择变动项目"
						option-filter-prop="children"
						style="width: 100%"
						v-model="changeItemCode"
						@change="search"
					>
						<a-select-option v-for="item in ChangeItems" :key="item.code">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="4">
					<a-select
						show-search
						allowClear
						showArrow
						mode="tags"
						placeholder="请选择变动原因"
						option-filter-prop="children"
						style="width: 100%"
						v-model="changeReasonCode"
						@change="search"
					>
						<a-select-option v-for="item in ChangeReasons" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="4" style="text-align: right">
					<a-input-search
						style="max-width: 200px;"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>
			</a-row>
			<a-row :gutter="[8, 8]">
				<a-col :span="24">
					<a-button type="primary" @click="exporter" v-if="isGranted('sl_salary_change_detail_excel')">
						<a-icon type="download" />
						<span>导出</span>
					</a-button>
					<a-button type="primary" @click="changRevoke" v-if="isGranted('sl_salary_change_revoke')">
						<a-icon type="rollback" />
						<span>撤销</span>
					</a-button>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 2000, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import * as _ from 'lodash';
import moment from 'moment';
import { ChangeRecordServiceProxy, CompanyServiceProxy, DepartmentServiceProxy } from '../../../shared/service-proxies';
import Dic from '../../../shared/utils/dic';
import { fileDownloadService } from '../../../shared/utils';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';

//薪资变动明细
export default {
	name: 'salary-change-detail',
	mixins: [AppComponentBase],
	data() {
		return {
			companyTreeList: [],
			comPanyId: undefined,
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			filterText: '',
			columns: [
				{
					title: '工号',
					dataIndex: 'jobNumber',
					sorter: true,
					fixed: 'left',
					width: 120,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					sorter: true,
					fixed: 'left',
					width: 120,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '公司',
					dataIndex: 'companyName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyName' },
				},
				{
					title: '部门',
					dataIndex: 'depantment',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'depantment' },
				},
				{
					title: '生效日期',
					dataIndex: 'effectiveDate',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'effectiveDate' },
				},
				{
					title: '创建时间',
					dataIndex: 'creationTime',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'creationTime' },
				},
				{
					title: '变更项目',
					dataIndex: 'changeItemName',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeItemName' },
				},
				{
					title: '变更方式',
					dataIndex: 'changeWayName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeWayName' },
				},
				{
					title: '更改值',
					dataIndex: 'changeValue',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeValue' },
				},
				{
					title: '原金额',
					dataIndex: 'originalAmount',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'originalAmount' },
				},
				{
					title: '新金额',
					dataIndex: 'newAmount',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'newAmount' },
				},
				{
					title: '变更原因',
					dataIndex: 'changeReasonName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeReasonName' },
				},
				{
					title: '变更人',
					dataIndex: 'creatorUserName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'creatorUserName' },
				},
			],
			tableData: [],
			//变更原因
			changeReasonCode: undefined,
			ChangeReasons: [],
			//变更项
			changeItemCode: undefined,
			ChangeItems: [],

			//开始日期
			startDate: undefined,
			//结束日期
			endDate: undefined,
		};
	},
	async created() {
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._changeRecordServiceProxy = new ChangeRecordServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		try {
			this.loading = true;
			this.ChangeReasons = await Dic.getInstance().getDicAsync('QYB_SL_ChangeReason');
			this.ChangeItems = await this._changeRecordServiceProxy.getSalaryChangeItemsData();
			await this.companyTreeInit();
		} catch (e) {
		} finally {
			this.loading = false;
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._changeRecordServiceProxy
				.getPaged(
					undefined,
					this.startDate,
					this.endDate,
					this.comPanyId,
					this.departmentId,
					this.changeItemCode,
					this.changeReasonCode,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.effectiveDate = item.effectiveDate ? moment(item.effectiveDate).format('YYYY-MM-DD') : '';
						item.creationTime = item.creationTime
							? moment(item.creationTime).format('YYYY-MM-DD HH:mm')
							: '';
					});
					this.totalItems = res.totalCount;
				});
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		companyTreeInit() {
			return new Promise((resolve) => {
				this._CompanyConfigServiceProxy
					.getPaged(undefined, '', 100, 0)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						this.companyList = res.items;
						this.companyList.forEach((item) => {
							if (item.parentId == 0) {
								var obj = {
									key: item.id,
									value: item.id,
									title: item.fullName,
								};
								this.companyTreeList.push(obj);
							}
						});
						this.initCompanyTree(this.companyTreeList);
						resolve();
					});
			});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.comPanyId = value;
			this.departmentId = undefined;
			this.refresh();
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		//生效日期选择
		onActiveDateChange(e) {
			this.startDate = e[0];
			this.endDate = e[1];
			this.refresh();
		},

		/**
		 * 导出
		 */
		exporter() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._changeRecordServiceProxy
				.getToExcelFile(
					undefined,
					this.startDate,
					this.endDate,
					this.comPanyId,
					this.departmentId,
					this.changeItemCode,
					this.changeReasonCode,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},

		changRevoke() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('请至少选择一项进行撤销');
			}
			if (this.selectedRows.length > 1) {
				return abp.message.warn('只能选择一项进行撤销');
			}
			var model = this.selectedRows[0];
			model.effectiveDate = moment(model.effectiveDate);
			var entiyinfo = {
				Id: model.id,
				EmpId: model.empId,
				EffectiveDate: model.effectiveDate,
				ChangeItem: model.changeItem,
				OriginalAmount: model.originalAmount,
				ChangeValue: model.changeValue,
			};

			this.$confirm({
				title: '确认操作',
				content: '您确定要撤销吗?',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;
					this._changeRecordServiceProxy
						.changRevoke(entiyinfo)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.getData();
							this.$notification['success']({
								message: this.l('SavedSuccessfully'),
							});
						});
				},
			});
		},
	},
};
</script>

<style scoped></style>
